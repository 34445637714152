import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"

//images

import house2 from "../images/residential/house2.jpg"
import house3 from "../images/residential/house3.jpg"
import house4 from "../images/residential/house4.jpg"
import house5 from "../images/residential/house5.jpg"
import house6 from "../images/residential/house6.jpg"
import house7 from "../images/residential/house7.jpg"
import house8 from "../images/residential/house8.jpg"
import house9 from "../images/residential/house9.jpg"
import house10 from "../images/residential/house10.jpg"

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
})

const cards = [
  {
    img: house2,
  },
  {
    img: house10,
  },
  {
    img: house4,
  },

  {
    img: house6,
  },
  {
    img: house7,
  },

  {
    img: house3,
  },
]

function Residential(props) {
  const { classes } = props

  return (
    <Layout>
      <div>
        <CssBaseline />
        <div className="spaceWaster" />
        <main>
          {/* Hero unit */}
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
                style={{ fontSize: "56px" }}
              >
                RESIDENTIAL
              </Typography>
              <Typography
                component="h1"
                variant="h6"
                align="center"
                color="textPrimary"
                gutterBottom
                style={{ paddingBottom: "1vh" }}
              >
                BUILDING YOUR NEXT HOME
              </Typography>

              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                With over 200 years combined building experience, American Dream
                has what it takes to make your next home truly special.
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              />
              <div className={classes.heroButtons}>
                <Grid container spacing={16} justify="center">
                  <Grid item>
                    <a
                      href="mailto:info@adcalaska.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Button variant="contained" color="primary" size="large">
                        Get a quote today!
                      </Button>
                    </a>
                  </Grid>
                  {/* <Grid item>
                  <a
                    href="tel: 907-357-8640"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Button variant="contained" color="secondary" size="medium">
                      Call and ask us about our options
                      <br /> (907) 357-8640
                    </Button>
                  </a>
                </Grid> */}
                </Grid>
              </div>
            </div>
          </div>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            {/* End hero unit */}
            <Grid container spacing={40}>
              {cards.map(card => (
                <Grid item key={card} xs={12} sm={12} md={4} lg={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.img}
                      alt={card.title}
                    />

                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.title}
                      </Typography>
                      <Typography>{card.details}</Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Button size="small" color="primary">
                      View
                    </Button> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </main>
      </div>
    </Layout>
  )
}

Residential.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Residential)
